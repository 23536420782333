import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Success from '../Messages/Success'
import { useAuth } from '../../hooks/useAuth';


async function requestPassword(user_info) {
    let formdata = new FormData();
    formdata.append("username", user_info["username"]);
    return axios.post("/request_password", formdata)
}

export default function RequestPassword({ setUserInfo }) {
  // const { user_info, logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUserName] = useState();
  const [show_message, showMessage] = useState(false);
  const [message, setMessage] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    await requestPassword({
      "username": username,
    })
    .then(response => {
        setUserName("");
        return response;
    })
    .then(response => {
        setMessage("Du erhälst Dein neues Passwort an Deine e-Mail Adresse geschickt.");
        showMessage(true);
    });
  }

  const theme = createTheme();

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Neues Passwort anfordern
                </Typography>
                <Success
                    onShowMessage={showMessage}
                    show_message={show_message}
                    message={message}
                />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={e => setUserName(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                      Neues Passwort anfordern
                    </Button>
                </Box>
            </Box>
        </Container>
    </ThemeProvider>
  )
}
