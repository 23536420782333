import axios from 'axios'
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Error from '../Messages/Error'
import { useAuth } from '../../hooks/useAuth';

async function loginUser(credentials) {

    let formdata = new FormData();
    formdata.append("username", credentials["username"]);
    formdata.append("password", credentials["password"]);

    return axios.post("/login",formdata,{
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then(response => response.data.access_token);

}

async function getUserInfo(username) {
    return axios.get("/users/"+username)
    .then(response => response.data);
}

export default function Login() {
  const { login } = useAuth();
  const [login_username, setLoginUserName] = useState();
  const [login_password, setLoginPassword] = useState();
  const [login_remember, setLoginRemember] = useState();
  const [show_error, showError] = useState(false);
  const [error_message, setErrorMessage] = useState();

  const displayError = async error => {
    setErrorMessage(error.message);
    showError(true);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    return loginUser({
      "username": login_username,
      "password": login_password
    })
    .then((token) => {
        localStorage.setItem("token", token);
        return token;
    })
    .then((token) => {
        return getUserInfo(login_username).then(user_info => {
            return {
                'token' : token,
                'user_info' : user_info
            }
        });
    })
    .then((data) => {
        login(data);
    })
    .catch(error => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_info");
        displayError(error)
    });
  }

  const theme = createTheme();

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Anmeldung
                </Typography>
                <Error
                    onShowError={showError}
                    show_error={show_error}
                    error_message={error_message}
                />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={e => setLoginUserName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setLoginPassword(e.target.value)}
                    />
                    {/*
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Angemeldet bleiben?"
                        onChange={e => setLoginRemember(e.target.checked)}
                    />
                    */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                      Anmelden
                    </Button>
                    {
                    <Grid container>
                        <Grid item xs>
                            <Link to="/request-password" variant="body2">
                                Passwort vergessen?
                            </Link>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                    }
                </Box>
            </Box>
        </Container>
    </ThemeProvider>
  )
}