import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

export default function SetPassword({setPassword}) {

    const [is_error_password, setIsErrorPassword] = useState(false);
    const [error_password, setErrorPasswordMsg] = useState();

    const validate_password = (password) => {
        if (password) {
            if (password.length<10) {
                return false;
            }
        }
        return true;
    }

    const password_change = (password) => {
        if (validate_password(password)){
            setIsErrorPassword(false);
        } else {
            setIsErrorPassword(true);
            setErrorPasswordMsg("Das neue Passwort muss mindestens 10 Zeichen lang sein.");
        }
        setPassword(password);
    }

    return(
        <TextField
            margin="normal"
            required
            fullWidth
            label="Neues Passwort eingeben"
            type="password"
            error={is_error_password}
            helperText={is_error_password && error_password}
            onChange={e => {password_change(e.target.value)}}
        />
    );
}