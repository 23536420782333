import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import FlightIcon from '@mui/icons-material/Flight';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserIcon from './UserIcon';
import { useAuth } from "../../hooks/useAuth";


export default function Header() {
  const { is_logged_in, user_info } = useAuth();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <FlightIcon sx={{ mr: 2 }} />
            <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }} noWrap>Fliegergruppe Schwäbisch Gmünd e.V.</Typography>
            {is_logged_in === true &&
                <>
                <Typography>{user_info.firstname + " " + user_info.lastname}</Typography>
                <UserIcon></UserIcon>
                </>
            }
        </Toolbar>
      </AppBar>
    </Box>  
  );
}