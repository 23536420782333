import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Error from '../Messages/Error'
import PasswordField from "./PasswordField"
import ConfirmPasswordField from "./ConfirmPasswordField"
import { useAuth } from '../../hooks/useAuth';


async function setPassword(credentials) {
    var formdata = new FormData();
    formdata.append("username", credentials["username"]);
    formdata.append("password_old", credentials["password_old"]);
    formdata.append("password_new", credentials["password_new"]);

    return axios.post("/set_password", formdata)

}

const validate_password = (password) => {
    if (password) {
        if (password.length<8) {
            return false;
        }
    }
    return true;
}

export default function SetPassword({ setUserInfo }) {
  const { user_info, logout } = useAuth();
  const navigate = useNavigate();
  const [username, setUserName] = useState();
  const [password_old, setPasswordOld] = useState();
  const [password_new, setPasswordNew] = useState();
  const [password_new_confirm, setPasswordNewConfirm] = useState();
  const [is_error_password, setIsErrorPassword] = useState();
  const [show_error, showError] = useState(false);
  const [error_message, setErrorMessage] = useState();

  const displayError = async error => {
    if (error.response.status === 401){
        setErrorMessage("Unbekannter Benutzer oder falsches Passwort.");
    } else {
        setErrorMessage(error.response.statusText);
    }  
    showError(true);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    await setPassword({
      "username": username,
      "password_old": password_old,
      "password_new": password_new
    })
    .then(response => {
        return response;
    })
    .then(response => {
        logout();
    })
    .catch(error => {
        displayError(error)
    });
    
  }

  const theme = createTheme();

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Passwort ändern
                </Typography>
                <Error
                    onShowError={showError}
                    show_error={show_error}
                    error_message={error_message}
                />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={e => setUserName(e.target.value)}
                    />
                     <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password0"
                        label="Aktuelles Passwort eingeben"
                        type="password"
                        id="password0"
                        onChange={e => {
                            setPasswordOld(e.target.value);
                        }}
                    />
                    <PasswordField setPassword={setPasswordNew}/>
                    <ConfirmPasswordField password_new={password_new} setPassword={setPasswordNewConfirm}/>
                    <Link href="reset-password">Passwort vergessen?</Link>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={is_error_password}
                    >
                      Passwort ändern
                    </Button>
                </Box>
            </Box>
        </Container>
    </ThemeProvider>
  )
}
