import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function Home() {
  return (
      <>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              FLG GD
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              Hir findet Ihr verschiedene Links zu Webseiten / Anwendungen der Fliegergruppe Schwäbisch Gmünd e.V.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" href="https://www.vereinsflieger.de">Vereinsflieger</Button>
              <Button variant="outlined" href="https://www.webcam.flg-gd.de/">Webcam</Button>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Vereinsflieger
                    </Typography>
                    <Typography>
                    vereinsflieger.de - Die Online-Vereinsverwaltung speziell für Flugsportvereine
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to="https://vereinsflieger.de/">
                      <Button size="small">&Ouml;ffnen</Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Webcam
                    </Typography>
                    <Typography>
                      Hornberg Webcams
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to="https://webcam.flg-gd.de/">
                      <Button size="small">&Ouml;ffnen</Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Interne Homepage
                    </Typography>
                    <Typography>
                      Unsere interne Homepage.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to="https://wiki.flg-gd.de/">
                      <Button size="small">&Ouml;ffnen</Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    FLG Cloud
                    </Typography>
                    <Typography>
                      Unsere Nextcloud Instanz
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link to="https://cumulus.flg-gd.de">
                      <Button size="small">&Ouml;ffnen</Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
          </Grid>
        </Container>
      </>
  );
}