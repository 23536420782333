import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Route, Routes} from "react-router-dom";

import Home from './components/Home/Home'
import Login from './components/Login/Login'
import SetPassword from './components/SetPassword/SetPassword'
import ResetPassword from './components/ResetPassword/ResetPassword'
import RequestPassword from './components/ResetPassword/RequestPassword'
import Layout from './Layout'
import NoPage from './components/NoPage/NoPage'
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";


const theme = createTheme();

const get_user_info =() => {
    let user_info = localStorage.getItem("user_info");
    if (user_info === undefined) {
        return undefined;
    } else {
        return JSON.parse(user_info);
    }
}

export default function App() {

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Layout/>} >
                        <Route index element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        } />
                        <Route path="login" element={<Login/>} />
                        <Route path="set-password" element={<SetPassword />} />
                        <Route path="request-password" element={<RequestPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </AuthProvider>
        </ThemeProvider>
    );

  }