import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

export default function ConfirmPasswordField({password_new, setPassword}) {

  const [is_error_password, setIsErrorPassword] = useState(false);
  const [error_password, setErrorPasswordMsg] = useState();

  const password_change = (password) => {
      if (password !== password_new){
        setIsErrorPassword(true);
        setErrorPasswordMsg("Die beiden neuen Passwörter sind nicht gleich.");
      } else {
        setIsErrorPassword(false);
      }
      setPassword(password);
  }

  return(
      <TextField
          margin="normal"
          required
          fullWidth
          label="Neues Passwort wiederholen"
          type="password"
          error={is_error_password}
          helperText={is_error_password && error_password}
          onChange={e => {password_change(e.target.value)}}
      />
  );
}