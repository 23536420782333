import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../helpers/useLocalStorage";

export const authContext = createContext(null);

function useProvideAuth() {
  const [is_logged_in, setIsLoggedIn] = useLocalStorage("is_logged_in", false);
  const [token, setToken] = useLocalStorage("token", null);
  const [user_info, setUserInfo] = useLocalStorage("user_info", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (data) => {
    setToken(data.token);
    setUserInfo(data.user_info);
    setIsLoggedIn(true);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null);
    setUserInfo(null);
    setIsLoggedIn(false);
    navigate("/login", { replace: true });
  };

  return {
      is_logged_in,
      token,
      user_info,
      login,
      logout
    };

};

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};