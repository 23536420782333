import axios from 'axios'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

const API_URL =
    process.env.NODE_ENV === 'production'
        ? 'https://start.flg-gd.de/api'
        : 'http://localhost:5000/';


// Add a request interceptor 
axios.interceptors.request.use(function (config) {
  if (localStorage.getItem("token")){
    let token = localStorage.getItem("token").replaceAll('"','');
    config.headers.Authorization = "Bearer " + token;
  }
  config.baseURL = API_URL;
  return config;
}, function (error) {     
  // Do something with request error     
  return Promise.reject(error);   
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    error.message = 'Dein Passwort ist falsch oder Dein User ist unbekannt.';
  }
  else if (error.response.status === 403) {
    error.message = 'Sorry, Du darfst das leider nicht tun.';
  }
  // Do something with response error
  return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
